import { ENABLE_TEST_FLAGS, DISABLE_TEST_FLAGS, TOGGLE_PREFERENCE } from './test-flag-actions';
import { transformPreferenceStringToObj, getAbTestTypeFromPreferences } from './useTestFlags';

export default (state = {}, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case ENABLE_TEST_FLAGS:
            return {
                ...(state || {}),
                enabled: true,
            };
        case DISABLE_TEST_FLAGS:
            return {
                ...(state || {}),
                enabled: false,
            };
        case TOGGLE_PREFERENCE:
            const { preferences } = state || {};
            const { groupNumber, enabled } = payload || {};
            if (isNaN(Number(groupNumber))) return state;

            const newPreferenceStringObj = transformPreferenceStringToObj(preferences);
            newPreferenceStringObj[groupNumber] = enabled ? 1 : 0;

            const preferenceStringKeys = Object.keys(newPreferenceStringObj).sort((a, b) =>
                a < b ? -1 : a > b ? 1 : 0,
            );
            const newPreferenceString = preferenceStringKeys.reduce(
                (allStr, groupNum, idx) => `${allStr}${idx > 0 ? ',' : ''}${groupNum}:${enabled ? 1 : 0}`,
                '',
            );

            let abTestType = getAbTestTypeFromPreferences(newPreferenceStringObj);

            return {
                ...(state || {}),
                preferences: newPreferenceString,
                ...(abTestType && { abTestType }),
            };
        default:
            return state;
    }
};
